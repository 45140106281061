function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains('gs_reveal_fromLeft')) {
    x = -200;
    y = 0;
  } else if (elem.classList.contains('gs_reveal_fromRight')) {
    x = 200;
    y = 0;
  }
  elem.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  elem.style.opacity = '0';
  elem.classList.add('isActive');

  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1.95,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'expo',
      overwrite: 'auto',
    },
  );
}

function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
  elem.classList.remove('isActive');
}

document.addEventListener('DOMContentLoaded', function () {
  gsap.registerPlugin(ScrollTrigger);

  const gsRevealInit = () => {
    gsap.utils
      .toArray('.gs_reveal, .gs_reveal_fromLeft, .gs_reveal_fromRight')
      .forEach(function (elem) {
        //  hide(elem); // assure that the element is hidden when scrolled into view

        ScrollTrigger.create({
          trigger: elem,
          onEnter: function () {
            animateFrom(elem);
          },
        });
      });
  };

  if (window.innerWidth > 991) {
    gsRevealInit();
  }
});

// gs_reveal ipsType_center
// gs_reveal gs_reveal_fromLeft
// gs_reveal gs_reveal_fromRight

const lenis = new Lenis();

// lenis.on('scroll', (e) => {
//     // console.log(e)
// })

lenis.on('scroll', ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 700);
});

gsap.ticker.lagSmoothing(0);

// input label mover
document.querySelectorAll('.form-item label').forEach((label) => {
  const input = label.parentElement.querySelector('input, textarea');

  input.addEventListener('focus', () => {
    label.classList.add('up');
  });

  input.addEventListener('blur', () => {
    if (input.value == '') {
      label.classList.remove('up');
    } else {
      input.classList.add('not-empty');
    }
  });
});

const changeLanguage = (lang) => {
  document.querySelector(`img[alt='${lang}']`)?.click();
  // en_US
  // de_DE
};

// language
const currentLanguage = document.querySelector('[data-current-language]')
  ? document.querySelector('[data-current-language]').getAttribute('data-current-language')
  : null;
if (currentLanguage) {
  document
    .querySelector(`[onclick="changeLanguage('${currentLanguage}')"]`)
    .classList.add('current-language');
}

document.querySelector('.translate-block-title').addEventListener('click', () => {
  document.querySelector('.translate-block').classList.toggle('hide-content');
});

document.querySelectorAll('.menu-button-open').forEach((button) => {
  const body = document.querySelector('body');
  button.addEventListener('click', () => {
    if (!body.classList.contains('menu-open')) {
      body.classList.add('menu-open');
    } else {
      body.classList.remove('menu-open');
    }

    body.classList.add('menu-animation');

    setTimeout(() => {
      body.classList.remove('menu-animation');
    }, 16 * 0.06);
  });
});
//

// popups

const closePopup = (button) => {
  button.closest('.popup').classList.add('hide');
};

if (
  localStorage.getItem('softwareLocalStorage') != 'true' &&
  document.querySelector('.popup-cookie')
) {
  document.querySelector('.popup-cookie').classList.remove('hide');

  document.querySelector('.button-cookie').addEventListener('click', () => {
    localStorage.setItem('softwareLocalStorage', 'true');
  });
}
//

//
document.querySelectorAll('.popup-menu .row *:not(small)').forEach((el, index) => {
  el.style.cssText = `transition-delay: ${index * 0.06}s;`;
});
//

// current page

function parseUri(str) {
  var o = parseUri.options,
    m = o.parser[o.strictMode ? 'strict' : 'loose'].exec(str),
    uri = {},
    i = 14;

  while (i--) uri[o.key[i]] = m[i] || '';

  uri[o.q.name] = {};
  uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
    if ($1) uri[o.q.name][$1] = $2;
  });

  return uri;
}

parseUri.options = {
  strictMode: false,
  key: [
    'source',
    'protocol',
    'authority',
    'userInfo',
    'user',
    'password',
    'host',
    'port',
    'relative',
    'path',
    'directory',
    'file',
    'query',
    'anchor',
  ],
  q: {
    name: 'queryKey',
    parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
  },
  parser: {
    strict:
      /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    loose:
      /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
  },
};

let pathname = window.location.pathname;
let menuElem = document.querySelectorAll(
  'header a:not(.logo), footer a:not(.logo), .popup-menu a, .banner nav a',
);

menuElem.forEach((el) => {
  if (parseUri(el.getAttribute('href')).path == pathname) {
    el.classList.add('current-page');
  }
  if (parseUri(el.getAttribute('href')).path.includes('account') && pathname == '/account/') {
    el.classList.add('current-page');
  }
});

// end current page

// preloader
const preloader = document.querySelector('.popup-preloader');
const preloaderNumberEl = document.querySelector('.preloader-number');
const preloaderProgres = document.querySelectorAll('.preloader-progres');
let preloaderNumber = 27;

if (preloader) {
  let currentPercent = 0;
  const animationInterval = setInterval(() => {
    preloaderNumberEl.textContent = currentPercent + ' %';
    preloaderProgres.forEach((el) => (el.style.cssText += `width: ${currentPercent}%;`));

    currentPercent++;
    if (currentPercent >= preloaderNumber) clearInterval(animationInterval);
  }, 20);

  document.addEventListener('DOMContentLoaded', function () {
    const animationInterval2 = setInterval(() => {
      preloaderNumberEl.textContent = currentPercent + 1 + ' %';
      preloaderProgres.forEach((el) => (el.style.cssText += `width: ${currentPercent + 1}%;`));

      currentPercent++;

      if (currentPercent >= 100) {
        clearInterval(animationInterval2);
        preloader.classList.add('page-ready');

        setTimeout(() => {
          preloader.classList.add('page-ready-2');
        }, 600);

        setTimeout(() => {
          preloader.classList.add('hide');
        }, 1200);
      }
    }, 5);
  });
}

// cursor

const move = document.getElementById('mouse-circle');

document.body.onpointermove = (event) => {
  const { clientX, clientY } = event;

  move.animate(
    {
      left: `${clientX}px`,
      top: `${clientY}px`,
    },
    { duration: 450, fill: 'forwards' },
  );
};

$(document).on('mousemove', function (e) {
  mouseX = e.clientX;
  mouseY = e.clientY;
});
// yellow circle
$('a, button').on('mouseenter', function () {
  move.classList.add('active');
  // follower.addClass("active");
});

$('a, button').on('mouseleave', function () {
  move.classList.remove('active');
  // follower.removeClass("active");
});

// banner dark circles

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

if (document.querySelector('.banner .circle-bg')) {
  setInterval(() => {
    document
      .querySelectorAll('.banner .circle-bg')
      .forEach(
        (circle) =>
          (circle.style.cssText = `top: ${getRandomArbitrary(1, 85)}%; left: ${getRandomArbitrary(
            1,
            85,
          )}%`),
      );
  }, 500 * 4.4);
}

// details
class Accordion {
  constructor(el) {
    this.el = el;
    this.wrap = null || this.el.closest('[details-wrap]');
    this.summary = el.querySelector('summary');
    this.content = el.querySelector('.accordion-content');
    this.expandIcon = this.summary.querySelector('.accordion-icon');
    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;
    this.summary.addEventListener('click', (e) => this.onClick(e));
  }

  onClick(e) {
    e.preventDefault();
    this.el.style.overflow = 'hidden';

    if (this.isClosing || !this.el.open) {
      this.open();
    } else if (this.isExpanding || this.el.open) {
      this.shrink();
    }
  }

  shrink() {
    this.isClosing = true;

    const startHeight = `${this.el.offsetHeight}px`;
    const endHeight = `${this.summary.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.el.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: 'ease-out',
      },
    );
    this.animation.onfinish = () => {
      return this.onAnimationFinish(false);
    };
    this.animation.oncancel = () => {
      return (this.isClosing = false);
    };
  }

  open() {
    this.el.style.height = `${this.el.offsetHeight}px`;
    this.el.open = true;
    window.requestAnimationFrame(() => this.expand());
  }

  expand() {
    this.isExpanding = true;

    const startHeight = `${this.el.offsetHeight}px`;
    const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.el.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 350,
        easing: 'ease-out',
      },
    );

    this.animation.onfinish = () => {
      return this.onAnimationFinish(true);
    };
    this.animation.oncancel = () => {
      return (this.isExpanding = false);
    };
  }

  onAnimationFinish(open) {
    this.el.open = open;
    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;
    this.el.style.height = this.el.style.overflow = '';
  }
}

document.querySelectorAll('details').forEach((el) => {
  new Accordion(el);
});

// end details

// change input submit to button submit

let allButton = document.querySelectorAll('input.button-more-info').forEach((el) => {
  let newButton = document.createElement('button');
  newButton.setAttribute('type', 'button');
  // newButton.classList.add("um-button");
  // newButton.classList.add("um-button");
  el.classList.forEach((elem) => {
    newButton.classList.add(elem);
  });
  newButton.innerText = el.getAttribute('value');

  newButton.innerHTML += `
    <div class="svg-wrap">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 13.134C0.0217074 13.4101 -0.142168 14.0217 0.133975 14.5C0.410117 14.9783 1.02171 15.1422 1.5 14.866L0.5 13.134ZM19.2864 4.25882C19.4294 3.72535 19.1128 3.17702 18.5793 3.03407L9.88599 0.704703C9.35253 0.561761 8.80419 0.878344 8.66125 1.41181C8.51831 1.94528 8.83489 2.49361 9.36836 2.63655L17.0958 4.70711L15.0252 12.4345C14.8823 12.968 15.1989 13.5163 15.7323 13.6593C16.2658 13.8022 16.8141 13.4856 16.9571 12.9522L19.2864 4.25882ZM1.5 14.866L18.8205 4.86603L17.8205 3.13397L0.5 13.134L1.5 14.866Z" fill="#F3F3F3"></path>
                    </svg>
                </div>`;

  el.style.cssText = 'height: 0; width: 0; padding: 0; margin: 0; transition: 0s; display: none;';

  newButton.addEventListener('click', () => {
    el.click();
  });

  el.parentNode.insertBefore(newButton, el);
  // el.parentNode.append(newButton);
});

// end change input submit to button submit

// tabs logic

document.querySelectorAll('[data-tab-button]').forEach((button) => {
  button.addEventListener('click', () => {
    let tabHash = button.getAttribute('data-tab-button');
    let tabsWrap = button.closest('[data-tab-wrap]');

    if (
      button.classList.contains('data-tab-button-second-click') &&
      button.classList.contains('tab-active')
    ) {
      // hide current tab
      tabsWrap
        .querySelectorAll('[data-tab-content]')
        .forEach((item) => item.classList.add('tab-hide'));

      button.classList.remove('tab-active');
    } else {
      tabsWrap
        .querySelectorAll('[data-tab-button]')
        .forEach((tabButton) => tabButton.classList.remove('tab-active'));

      tabsWrap
        .querySelectorAll('[data-tab-content]')
        .forEach((item) => item.classList.add('tab-hide'));

      button.classList.add('tab-active');

      tabsWrap
        .querySelectorAll(`[data-tab-content="${tabHash}"]`)
        .forEach((item) => item.classList.remove('tab-hide'));

      // if have child tabs
      if (tabsWrap.querySelector('[data-tab-content] [data-tab-wrap]')) {
        let childWrap = tabsWrap.querySelectorAll('[data-tab-content] [data-tab-wrap]');

        childWrap.forEach((wrap) => {
          wrap.querySelector('[data-tab-button]').classList.add('tab-active');

          wrap.querySelector('[data-tab-content]').classList.remove('tab-hide');
        });
      }
    }
  });
});

// end tabs logic

$('.review-slider').slick({
  infinite: true,
  slidesToShow: 1,
  prevArrow: '.slider-prev',
  nextArrow: '.slider-next',
});

// const logoBlack = document.querySelector('#logo-black');
// const logoWhite = document.querySelector('#logo-white');
// const header = document.querySelector('header'); // або замініть на селектор вашого хедера
// const section = document.querySelector('.why-us.home-page.bg-section-dark');

// window.addEventListener('scroll', function () {
//   const headerHeight = header.offsetHeight;
//   const scrollPosition = window.scrollY;
//   const sectionTop = section.offsetTop;
//   const sectionHeight = section.offsetHeight;
//   const sectionBottom = sectionTop + sectionHeight;

//   // Перевіряємо, чи накладається хедер на секцію
//   if (scrollPosition + headerHeight > sectionTop && scrollPosition < sectionBottom) {
//     // Хедер знаходиться над секцією - змінюємо логотип
//     logoBlack.classList.add('hide');
//     logoWhite.classList.remove('hide');
//   } else {
//     // Хедер поза секцією - повертаємо початковий логотип
//     logoBlack.classList.remove('hide');
//     logoWhite.classList.add('hide');
//   }
// });

const logoBlack = document.querySelector('#logo-black');
const logoWhite = document.querySelector('#logo-white');
const header = document.querySelector('header'); // Замість 'header' використайте селектор вашого хедера
const darkSections = document.querySelectorAll(
  'section.bg-section-dark, .about-page.banner, .services-page.banner',
);

const changeLogoIfBgBlackOnScroll = () => {
  const headerRect = header.getBoundingClientRect();
  let logoChanged = false;

  for (let i = 0; i < darkSections.length; i++) {
    const section = darkSections[i];
    const sectionRect = section.getBoundingClientRect();

    // Перевіряємо, чи перетинаються хедер та секція
    if (headerRect.bottom > sectionRect.top && headerRect.top < sectionRect.bottom) {
      // Хедер накладається на темну секцію - змінюємо логотип на світлий
      logoBlack.classList.add('hide');
      logoWhite.classList.remove('hide');
      logoChanged = true;
      break; // Зупиняємо перебір після знаходження накладання
    }
  }

  if (!logoChanged) {
    // Хедер не накладається на темні секції - використовуємо чорний логотип
    logoBlack.classList.remove('hide');
    logoWhite.classList.add('hide');
  }
};

changeLogoIfBgBlackOnScroll();

window.addEventListener('scroll', () => changeLogoIfBgBlackOnScroll());
